// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logo__OGeur {
  width: 86px;
  height: 55px;
  overflow: visible;
}
.Logo_logo__OGeur:active .Logo_first__9rglR, .Logo_logo__OGeur:hover .Logo_first__9rglR {
  transform: translateY(-5px);
  transition-delay: 0.07s;
}
.Logo_logo__OGeur:active .Logo_second__d3yfD, .Logo_logo__OGeur:hover .Logo_second__d3yfD {
  transform: translateY(-3px);
}

.Logo_logo__svg__OFAGn {
  fill: #ff6f19;
  transition: 0.35s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/logo/Logo.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;EACA,YAAA;EACA,iBAAA;AADD;AAKE;EACC,2BAAA;EACA,uBAAA;AAHH;AAME;EACC,2BAAA;AAJH;;AASA;EACC,aCrBQ;EDsBR,sBClBI;ADYL","sourcesContent":["@import 'src/_variables';\n\n.logo {\n\twidth: 86px;\n\theight: 55px;\n\toverflow: visible;\n\n\t&:active,\n\t&:hover {\n\t\t.first {\n\t\t\ttransform: translateY(-5px);\n\t\t\ttransition-delay: 0.07s;\n\t\t}\n\n\t\t.second {\n\t\t\ttransform: translateY(-3px);\n\t\t}\n\t}\n}\n\n.logo__svg {\n\tfill: $accent;\n\ttransition: $tr;\n}\n","$accent: #ff6f19;\n$grey: #eaeaea;\n$w: #fff;\n$dark: #000;\n$tr: 0.35s ease;\n$br40: 40px;\n$Jost: 'Jost-Regular';\n$Jost300: 'Jost-Light';\n$Jost500: 'Jost-Medium';\n$Jost600: 'Jost-SemiBold';\n$Jost700: 'Jost-Bold';\n$Jost800: 'Jost-ExtraBold';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Logo_logo__OGeur`,
	"first": `Logo_first__9rglR`,
	"second": `Logo_second__d3yfD`,
	"logo__svg": `Logo_logo__svg__OFAGn`
};
export default ___CSS_LOADER_EXPORT___;
