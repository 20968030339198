import { Dialog, Transition } from '@headlessui/react'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../..'
import { editCreateProduct } from '../../http/userAPI'
import Dropdown from '../ui/Dropdown/Dropdowns'
import {
	setBrands,
	setCategories,
	setCategoriesTypes,
	setGeneration,
	setMarks,
} from '../ui/Navbar/NavbarSelected/NavbarSelectedUtils'
import { findItemById, resetSearchProducts } from '../ui/ShopDeviceList/SearchUtils'
import AccessAlert from '../ui/modal/AccessAlert/AccessAlert'
import ErrorAlert from '../ui/modal/ErrorAlert/ErrorAlert'

const EditProduct = observer(({ show, onHide, productActive }) => {
	const { search, device } = useContext(Context)
	const cancelButtonRef = useRef(null)
	const [errorAlertShow, setErrorAlertShow] = useState(false)
	const [accessAlertShow, setAccessAlertShow] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')
	const [alertTitle, setAlertTitle] = useState('')
	const [errorStatus, setErrorStatus] = useState('')

	const [price, setPrice] = useState('')
	const [address, setAddress] = useState('')
	const [description, setDescription] = useState('')
	const [title, setTitle] = useState('')

	useEffect(() => {
		resetSearchProducts(search, device)
		setPrice(productActive.price)
		setDescription(productActive.description)
		setAddress(productActive.address)
		setTitle(productActive.title)

		setMarks(productActive?.car_gen?.car_model?.car_brand_id, device, search)
		setBrands(productActive?.car_gen?.car_model?.id, device, search)
		setCategories(productActive?.detail_type?.detail_category_id, search, device)
		setGeneration(productActive?.car_gen?.id, search, device, true)
		setCategoriesTypes(productActive?.detail_type?.id, search, device)
	}, [show, search, device, productActive])

	async function editProduct(e, productId, price, address, title, description, genId, type) {
		e.preventDefault()
		try {
			let condition = findItemById(device?.conditions, search.conditionFilter)
			await editCreateProduct(productId, price, address, title, description, condition?.value, genId, type)
			setAlertTitle('Вы успешно отредактирвоали товар!')
			setAccessAlertShow(true)
		} catch (error) {
			setAlertMessage(error.response)
			setErrorStatus(error.response?.status)
			setAlertTitle('Ошибка редактирования объявления ')
			setErrorAlertShow(true)
		}
	}

	return (
		<>
			<Transition.Root show={show} as={Fragment}>
				<Dialog
					as='div'
					className='relative z-10'
					initialFocus={cancelButtonRef}
					onClose={() => setAccessAlertShow(false)}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
						<div
							className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'
							onClick={e => {
								if (!e.target.classList.contains('w-full')) {
									onHide(false)
								}
							}}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
								<Dialog.Panel className='relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
									<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
										<div className='sm:flex sm:items-start'>
											<div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
												<Dialog.Title as='h3' className='text-lg font-bold leading-6 text-gray-900'>
													Отредактировать объявление
												</Dialog.Title>
												<div className='mt-6'>
													<form className='w-full'>
														<input
															className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
															placeholder='Цена'
															value={price}
															type='price'
															onChange={e => {
																let inputValue = e.target.value
																let onlyDigits = inputValue.replace(/\D/g, '')
																setPrice(onlyDigits)
															}}
														/>

														<Dropdown
															key={search.keyCounter}
															items={device.conditions}
															name={'Состояние'}
															className='w-full'
															fullWidth={true}
															type={true}
															selectedId={findItemById(device.conditions, productActive.condition)}
															onChangeCallback={selectedTypeValue => {
																search.setConditionFilter(selectedTypeValue)
															}}
														/>

														<textarea
															className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
															placeholder='Описание'
															value={description}
															onChange={e => setDescription(e.target.value)}
														/>

														<input
															className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
															placeholder='Адрес'
															onChange={e => setAddress(e.target.value)}
															value={address}
														/>

														<input
															className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
															placeholder='Название'
															onChange={e => setTitle(e.target.value)}
															value={title}
														/>

														<Dropdown
															key={`generationMark-${search.keyCounter}`}
															items={device.types}
															name={'Марка'}
															className={`w-full ${
																device.types?.length > 0 ? '' : 'pointer-events-none opacity-50'
															}`}
															fullWidth={true}
															type={true}
															selectedId={productActive?.cer_gen?.car_model?.car_brand_id}
															onChangeCallback={selectedTypeValue => {
																setMarks(selectedTypeValue, device, search)
															}}
														/>
														<Dropdown
															key={`brandsDropdown-${search.stamp + search.keyCounter}`}
															items={device.brands}
															name={'Модель'}
															className={`w-full ${
																device.brands?.length > 0 ? '' : 'pointer-events-none opacity-50'
															}`}
															fullWidth={true}
															type={true}
															selectedId={productActive?.car_gen?.car_model?.id}
															onChangeCallback={selectedTypeValue => {
																setBrands(selectedTypeValue, device, search)
															}}
														/>
														<Dropdown
															key={`generationDropdown-${
																search.stamp + search.model + search.keyCounter
															}`}
															items={device.generations}
															name={'Поколение'}
															className={`w-full ${
																device.generations?.length > 0
																	? ''
																	: 'pointer-events-none opacity-50'
															}`}
															fullWidth={true}
															type={true}
															selectedId={productActive?.car_gen?.id}
															onChangeCallback={selectedTypeValue => {
																setGeneration(selectedTypeValue, search, device)
															}}
														/>
														<Dropdown
															key={`generationCategories-${search.keyCounter}`}
															items={device.categories}
															name={'Категория'}
															className={`w-full ${
																device.categories?.length > 0
																	? ''
																	: 'pointer-events-none opacity-50'
															}`}
															fullWidth={true}
															type={true}
															selectedId={productActive?.detail_type?.detail_category_id}
															onChangeCallback={selectedTypeValue => {
																setCategories(selectedTypeValue, search, device)
															}}
														/>
														<Dropdown
															key={`brandsCategoriesTypes-${search.category + search.keyCounter}`}
															items={device.categoriesTypes}
															name={'Тип'}
															className={`w-full ${
																device.categoriesTypes?.length > 0
																	? ''
																	: 'pointer-events-none opacity-50'
															}`}
															fullWidth={true}
															type={true}
															selectedId={productActive?.detail_type?.id}
															onChangeCallback={selectedTypeValue => {
																setCategoriesTypes(selectedTypeValue, search, device)
															}}
														/>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
										<button
											type='button'
											className='inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent/90 sm:ml-3 sm:w-auto'
											onClick={e =>
												editProduct(
													e,
													productActive.id,
													price,
													address,
													title,
													description,
													search.generation,
													search.type,
												)
											}>
											Обновить
										</button>
										<button
											type='button'
											className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
											onClick={() => onHide(false)}
											ref={cancelButtonRef}>
											Закрыть
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<ErrorAlert
				open={errorAlertShow}
				setOpen={setErrorAlertShow}
				title={alertTitle}
				status={errorStatus}
				message={alertMessage}
			/>
			<AccessAlert
				open={accessAlertShow}
				setOpen={setAccessAlertShow}
				alertTitle={alertTitle}
				alertMessage={alertMessage}
			/>
		</>
	)
})

export default EditProduct
