// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbar__kSz7H {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 37px;
  padding-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AACD","sourcesContent":[".navbar {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding-top: 37px;\n\tpadding-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Navbar_navbar__kSz7H`
};
export default ___CSS_LOADER_EXPORT___;
