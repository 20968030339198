import { $authHost, $host } from '.'

export const registration = async (email, password, name, city, jwt_token) => {
	try {
		const response = await $host.post('/api/profile', {
			email: email,
			password: password,
			name: name,
			city: city,
			jwt_token: jwt_token
		})
		return response
	} catch (e) {
		console.error('Error:', e)
		throw e
	}
}

export const login = async (email, password) => {
	try {
		const response = await $host.post('/api/auth/login', {
			email: email,
			password: password,
		})
		return response
	} catch (e) {
		console.error('Error:', e.message)
		throw e
	}
}

export const profileInfo = async (profile_id = null) => {
	try {
		let route = profile_id === null ? '/api/account' : '/api/account/' + profile_id
		const response = await $authHost.get(route)
		return response
	} catch (e) {
		console.error(e)
	}
}

export const getRoom = async (profile_id ) => {
	try {
		const response = await $authHost.get('/api/chat/room/' + profile_id)
		return response
	} catch (e) {
		console.error(e)
	}
}

export const check = async () => {
	try {
		const response = await $authHost.get('/api/profile')
		return response
	} catch (e) {
		console.error(e)
	}
}

export const handleLogout = async () => {
	try {
		const response = await $authHost.post('/api/auth/logout')
		return response
	} catch (e) {
		console.error(e)
		throw e
	}
}

export const handleProfileProducts = async () => {
	try {
		const response = await $authHost.get('/api/profile/products')
		return response
	} catch (e) {
		console.error(e)
		throw e
	}
}

export const handleUpdateProfile = async (name, city, phone) => {
	try {
		const updateProfileData = {
			name: name,
			phone: phone,
			city: city,
		}
		const response = await $authHost.patch('/api/profile', JSON.stringify(updateProfileData))
		return response
	} catch (e) {
		console.error(e)
		throw e
	}
}

export const handleCreateProduct = async (
	price,
	address,
	title,
	description,
	condition,
	genId,
	type,
	image,
) => {

	const createProductData = {
		price: price,
		address: address,
		title: title,
		condition: condition,
		description: description,
		car_gen_id: genId,
		detail_type_id: type,
		image: image,
	}

	try {
		const response = await $authHost.post(
			'/api/profile/products',
			JSON.stringify(createProductData),
		)
		return response
	} catch (e) {
		console.error('Error create product:', e)
		throw e
	}
}

export const editCreateProduct = async (
	productid,
	price,
	address,
	title,
	description,
	condition,
	genId,
	type,
) => {
	const createProductData = {
		price: price,
		address: address,
		title: title,
		condition: condition,
		description: description,
		car_gen_id: genId,
		detail_type_id: type,
	}

	try {
		const response = await $authHost.patch(
			`/api/profile/products/${productid}`,
			JSON.stringify(createProductData),
		)
		return response
	} catch (e) {
		console.error('Error create product:', e)
		throw e
	}
}

export const deleteProduct = async id => {
	try {
		const response = await $authHost.delete(`/api/profile/products/${id}`)
		return response
	} catch (e) {
		console.error('Error delete product:', e)
		throw e
	}
}

export const sendSms = async(phone) =>
{
	try{
		const response = await $host.post('/api/auth/send-sms', {
			phone_number: phone
		})
		return response
	}
	catch(e){
		console.error('Error in sending sms')
		throw e
	}
}

export const confirmPhone = async(verification_id, verification_code) =>
{
	try{
		
		const response = await $host.post('/api/auth/confirm-phone', {
			verification_id: verification_id,
			verification_code: verification_code
		})
		return response
	}
	catch(e){
		console.error('Verification code is not valid')
		throw e
	}
}