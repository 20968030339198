import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Context } from '../../..'
import { fetchDevises } from '../../../http/deviceAPI'
import DeviceItem from '../DeviceItem/DeviceItem'
import Dropdown from '../Dropdown/Dropdowns'
import NavbarSelected from '../Navbar/NavbarSelected/NavbarSelected'
import { resetSearchProducts, searchProducts } from './SearchUtils'

const DeviceList = observer(() => {
	const { device, search } = useContext(Context)

	useEffect(() => {
		fetchDevises().then(data => {
			device.setDevice(data)
		})
		resetSearchProducts(search, device)
	}, [device, search])

	return (
		<div>
			<div className='mx-auto pb-12 flex flex-col xl:flex-row justify-between group relative w-full'>
				<div className='xl:border-r border-gray-900\/10 pb-6 xl:pb-0 xl:mr-6 relative xl:pr-12'>
					<div className='xl:sticky xl:w-80 top-6 xl:left-0'>
						<h4 className='title mb-4'>Сортировка:</h4>
						<div className='block xl:hidden'>
							<NavbarSelected />
						</div>
						<div>
							<div className='flex flex-col'>
								<div className='flex flex-col w-full mt-6 xl:mt-0'>
									<label>Цена</label>
									<div className='flex gap-x-3'>
										<input
											className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
											value={search.minPriceFilter}
											onChange={e => {
												search.setMinPriceFilter(e.target.value)
											}}
											placeholder='Цена от'
										/>
										<input
											className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
											value={search.maxPriceFilter}
											onChange={e => {
												search.setMaxPriceFilter(e.target.value)
											}}
											placeholder='Цена до'
										/>
									</div>

									<input
										className='flex p-3 bg-grey rounded-xl mt-3 w-full items-center justify-between placeholder:text-dark outline-none'
										placeholder='Город'
										value={search.cityFilter}
										onChange={e => {
											search.setCityFilter(e.target.value)
										}}
									/>

									<Dropdown
										key={`generationConditionFilter-${search.keyCounter}`}
										items={device.conditions}
										name={'Состояние'}
										className='w-full'
										fullWidth={true}
										isPageShop={true}
										type={true}
										onChangeCallback={selectedTypeValue => {
											search.setConditionFilter(selectedTypeValue)
										}}
									/>

									<Dropdown
										key={`generationSortFilter-${search.keyCounter}`}
										items={device.sortings}
										name={'Сортировка'}
										className='w-full'
										fullWidth={true}
										isPageShop={true}
										type={true}
										onChangeCallback={sortFilter => {
											search.setSortFilter(sortFilter)
										}}
									/>
									<Dropdown
										key={`generationCallback-${search.keyCounter}`}
										items={device.sortingsPrice}
										name={'Сортировать по'}
										className='w-full'
										fullWidth={true}
										isPageShop={true}
										type={true}
										onChangeCallback={descFilter => {
											search.setDescFilter(descFilter)
										}}
									/>
								</div>
								<div className='flex gap-x-3'>
									<button
										className='button w-fit mt-6'
										onClick={() => searchProducts(search, device)}>
										Поиск
									</button>
									<button
										className='button w-fit mt-6'
										onClick={() => resetSearchProducts(search, device)}>
										Сброс
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='flex flex-col border-t border-gray-900\/10 pt-3 pb-3 w-full'>
					{device.devices?.map(device => (
						<div key={device.id} className='w-full'>
							<DeviceItem key={device.id} device={device} />
						</div>
					))}
				</div>
			</div>
		</div>
	)
})

export default DeviceList
