// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Field_field__helcJ {
    display: flex;
    align-items: center
}
.Field_field__helcJ > input {
    border-style: none;
    background-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-timing-function: linear
}
.Field_field__helcJ > input::placeholder {
    --tw-text-opacity: 1;
    color: rgb(102 100 99 / var(--tw-text-opacity))
}
.Field_field__helcJ:focus-within .Field_icon__RoZvY {
    --tw-text-opacity: 1;
    color: rgb(255 111 25 / var(--tw-text-opacity))
}
.Field_field__helcJ .Field_icon__RoZvY {
    margin-right: 0.75rem;
    --tw-text-opacity: 1;
    color: rgb(88 86 84 / var(--tw-text-opacity));
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-timing-function: linear
}
.Field_field__helcJ .Field_error__liE4S {
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Field/Field.module.scss"],"names":[],"mappings":"AACC;IAAA,aAAA;IAAA;AAAA;AAEC;IAAA,kBAAA;IAAA,6BAAA;IAAA,8BAAA;IAAA,mBAAA;IAAA,+FAAA;IAAA,wDAAA;IAAA,0BAAA;IAAA;AAAA;AAAA;IAAA,oBAAA;IAAA;AAAA;AAKC;IAAA,oBAAA;IAAA;AAAA;AAID;IAAA,qBAAA;IAAA,oBAAA;IAAA,6CAAA;IAAA,+FAAA;IAAA,wDAAA;IAAA,0BAAA;IAAA;AAAA;AAIA;IAAA,mBAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".field {\n\t@apply flex items-center;\n\t> input {\n\t\t@apply border-none outline-none bg-transparent placeholder:text-[#666463] transition-colors duration-300 ease-linear;\n\t}\n\n\t&:focus-within {\n\t\t.icon {\n\t\t\t@apply text-accent;\n\t\t}\n\t}\n\t.icon {\n\t\t@apply mr-3 text-[#585654] transition-colors duration-300 ease-linear;\n\t}\n\n\t.error {\n\t\t@apply text-red-600 text-sm;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `Field_field__helcJ`,
	"icon": `Field_icon__RoZvY`,
	"error": `Field_error__liE4S`
};
export default ___CSS_LOADER_EXPORT___;
